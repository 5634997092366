import axios from "axios";
import { QueryClient } from "react-query";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    },
  },
});

// export const BASE_URL = "https://api.karizma.ai";
export const BASE_URL = "https://dev.karizma.ai";

export const apiClient = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

apiClient.interceptors.request.use(
  async (config: any) => {
    config.headers = {
      ...config.headers,
      "karizma-agent": "karizma_candidate_ext",
      karizmaVersion: 2,
    };

    return config;
  },
  (error) => Promise.reject(error)
);

apiClient.interceptors.response.use(
  (response) => response,
  async (error) => Promise.reject(error)
);
