import { useParams } from "react-router-dom";

//assets
import { logoIcon } from "../../assets/images";

//components
import { Loader, VideoPlayer } from "../../components";

//requests
import { useGetVideo } from "../../api/requests";

//styles
import styles from "./VideoViewerPage.module.scss";
import { useState } from "react";

const VideoViewerPage = () => {
  let params = useParams();

  const videoId = !!params?.id ? params.id : "";

  const { data, error, isLoading } = useGetVideo(videoId);

  const [showExtError, setShowExtError] = useState(false);

  const handleTryItNow = () => {
    const userAgent = navigator.userAgent;

    if (userAgent.match(/iPhone/i)) {
      window.open(
        "https://apps.apple.com/ua/app/karizma-jobs/id1669453687?l",
        "_blank"
      );
    } else if (userAgent.match(/Android/i)) {
      window.open(
        "https://play.google.com/store/apps/details?id=com.karizma_employee_new",
        "_blank"
      );
    } else {
      window.open("https://karizma.ai", "_blank");
    }
  };

  const avatar = data?.cvVideo?.candidate?.image;

  const firstName = data?.cvVideo?.candidate?.firstName;
  const lastName = data?.cvVideo?.candidate?.lastName;

  const city = data?.cvVideo?.candidate?.location?.city;
  const country = data?.cvVideo?.candidate?.location?.country;

  return (
    <div className={styles["container"]}>
      <div className={styles["container__header"]}>
        {!!data && (
          <>
            <div className={styles["container__avatar"]}>
              {!!avatar ? (
                <img src={avatar} alt="avatar" />
              ) : (
                <div>
                  {firstName[0]}
                  {lastName[0]}
                </div>
              )}
            </div>
            <p className={styles["container__name"]}>
              {firstName} {lastName}
              <p className={styles["container__location"]}>
                {city}
                {!!city && !!country && ","} {country}
              </p>
            </p>
          </>
        )}
      </div>

      <div className={styles["container__content"]}>
        {!!data && !showExtError && (
          <VideoPlayer
            src={data.cvVideo.url}
            handleError={() => setShowExtError(true)}
          />
        )}

        {(isLoading || !!error || showExtError) && (
          <div className={styles["container__content--centering"]}>
            {isLoading && <Loader color="#FFFFFF" />}
            {!!error && (
              <div className={styles["container__content--error"]}>
                Oops, the video wasn't found.
                <br />
                Seems the candidate deleted it
              </div>
            )}
            {showExtError && (
              <div className={styles["container__content--error"]}>
                Please wait. <br />
                The video is converted to a format supported by your browser.
                Try to reload a page in a few minutes
              </div>
            )}
          </div>
        )}
      </div>

      <div className={styles["container__footer"]}>
        <div>
          <p className={styles["container__footer--powered-by"]}>Powered by</p>
          <img src={logoIcon} className={styles["container__logo"]} />
          <p className={styles["container__footer--subtitle"]}>
            The easiest way to hire the best candidates
          </p>
        </div>
        <div
          onClick={handleTryItNow}
          className={styles["container__try-button"]}
        >
          Try it now
        </div>
      </div>
    </div>
  );
};

export default VideoViewerPage;
