const Loader = ({ width = 46, color = "#54585F", ...rest }) => {
  return (
    <svg
      width={width}
      height={width}
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23 41.6875C22.2061 41.6875 21.5625 41.0439 21.5625 40.25V33.8423C21.5625 33.0484 22.2061 32.4048 23 32.4048C23.7939 32.4048 24.4375 33.0484 24.4375 33.8423V40.25C24.4375 41.0439 23.7939 41.6875 23 41.6875ZM23 13.5957C22.2061 13.5957 21.5625 12.9521 21.5625 12.1582L21.5625 5.75C21.5625 4.95609 22.2061 4.3125 23 4.3125C23.7939 4.3125 24.4375 4.95609 24.4375 5.75L24.4375 12.1582C24.4375 12.9521 23.7939 13.5957 23 13.5957ZM9.78631 34.1811C9.22493 34.7425 9.22493 35.6527 9.78631 36.214C10.3477 36.7754 11.2579 36.7754 11.8192 36.214L16.3502 31.6831C16.9115 31.1217 16.9115 30.2116 16.3502 29.6502C15.7888 29.0888 14.8786 29.0888 14.3172 29.6502L9.78631 34.1811ZM29.6502 14.3172C29.0888 14.8786 29.0888 15.7888 29.6502 16.3501C30.2116 16.9115 31.1218 16.9115 31.6831 16.3501L36.2144 11.8189C36.7758 11.2575 36.7758 10.3473 36.2144 9.78592C35.653 9.22454 34.7429 9.22455 34.1815 9.78592L29.6502 14.3172ZM5.75 21.5625C4.95609 21.5625 4.3125 22.2061 4.3125 23C4.3125 23.7939 4.95609 24.4375 5.75 24.4375H12.1577C12.9516 24.4375 13.5952 23.7939 13.5952 23C13.5952 22.2061 12.9516 21.5625 12.1577 21.5625H5.75ZM33.8418 21.5625C33.0479 21.5625 32.4043 22.2061 32.4043 23C32.4043 23.7939 33.0479 24.4375 33.8418 24.4375H40.25C41.0439 24.4375 41.6875 23.7939 41.6875 23C41.6875 22.2061 41.0439 21.5625 40.25 21.5625H33.8418ZM34.1812 36.214C34.7426 36.7754 35.6528 36.7754 36.2142 36.214C36.7755 35.6527 36.7755 34.7425 36.2142 34.1811L31.6832 29.6502C31.1219 29.0888 30.2117 29.0888 29.6503 29.6502C29.0889 30.2116 29.0889 31.1217 29.6503 31.6831L34.1812 36.214ZM14.3173 16.3501C14.8787 16.9115 15.7889 16.9115 16.3503 16.3501C16.9116 15.7888 16.9116 14.8786 16.3503 14.3172L11.819 9.78592C11.2576 9.22455 10.3474 9.22455 9.78604 9.78592C9.22466 10.3473 9.22467 11.2575 9.78604 11.8189L14.3173 16.3501Z"
        fill={color}
      />
    </svg>
  );
};

export default Loader;
