import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ErrorPage, VideoViewerPage } from "../pages";
import { VIDEO_VIEWER_ROUTE } from "./paths";

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={VIDEO_VIEWER_ROUTE} element={<VideoViewerPage />} />
        <Route path={"*"} element={<ErrorPage />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
