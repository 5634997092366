import { ChangeEvent, useRef, useState } from "react";
import ReactPlayer from "react-player";
import dayjs from "dayjs";

// hooks
import { useBoolean } from "../../hooks";

// types
import { IVideoPlayerProps } from "../components.types";

// assets
import { Pause, Play } from "../../assets/icons";

// styles
import styles from "./VideoPlayer.module.scss";
import clsx from "clsx";

const countdownVideoDuration = (duration: number, played: number) => {
  return dayjs((duration - played) * 1000).format("mm:ss");
};

const VideoPlayer = ({ handleError, src }: IVideoPlayerProps) => {
  const videoPlayerRef = useRef<any>(null);

  const {
    value: playing,
    toggle: togglePlaying,
    setTrue: startPlaying,
    setFalse: pausePlaying,
  } = useBoolean(false);

  const [duration, setDuration] = useState(0);
  const [played, setPlayed] = useState(0);

  const onProgress = (state: { playedSeconds: number }) => {
    setPlayed(state.playedSeconds);
  };

  const onEnded = () => {
    pausePlaying();
  };

  const onSeekMouseDown = () => {
    pausePlaying();
  };

  const onSeekMouseUp = (e: any) => {
    startPlaying();

    videoPlayerRef?.current.seekTo(+e.target.value);
  };

  const onChangeSeek = (e: ChangeEvent<HTMLInputElement>) => {
    setPlayed(+e.target.value);
  };

  const isWebm = src.split(".").pop() === "webm";

  return (
    <div className={styles["video-player"]}>
      <ReactPlayer
        className={clsx(
          styles["video-player__wrapper"],
          isWebm && styles.rotate
        )}
        width="100%"
        height="100%"
        ref={videoPlayerRef}
        url={src}
        playing={playing}
        onDuration={setDuration}
        onProgress={onProgress}
        onEnded={onEnded}
        onError={(err) => handleError()}
      />
      <div className={styles["video-player__control"]}>
        <div className={styles["video-player__control--range-wrapper"]}>
          <div
            style={{ width: `${(played * 100) / duration}%` }}
            className={styles["video-player__control--range-progress"]}
          />
          <input
            className={styles["video-player__control--range"]}
            type="range"
            min={0}
            max={duration}
            step={0.0001}
            value={played}
            onChange={onChangeSeek}
            onMouseDown={onSeekMouseDown}
            onMouseUp={onSeekMouseUp}
          />
        </div>
        <div className={styles["video-player__control--box"]}>
          <div
            className={styles["video-player__control--play-button"]}
            onClick={togglePlaying}
          >
            {playing ? <Pause /> : <Play />}
          </div>
          <div className={styles["video-player__control--time"]}>
            {countdownVideoDuration(duration, played)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoPlayer;
