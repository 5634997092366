import { QueryClientProvider } from "react-query";
import Router from "./routes/Route";

import { queryClient } from "./api/apiClient";

//styles
import "./styles/styles.scss";

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Router />
    </QueryClientProvider>
  );
}

export default App;
