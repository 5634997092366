import { useCallback, useState } from "react";

// types
import { IUseBoolean } from "./hooks.types";

const useBoolean = (defaultValue?: boolean): IUseBoolean => {
  const [value, setValue] = useState(!!defaultValue);

  const setTrue = useCallback(() => setValue(true), []);
  const setFalse = useCallback(() => setValue(false), []);
  const toggle = useCallback(() => setValue((v) => !v), []);

  return { value, setValue, setTrue, setFalse, toggle };
};

export default useBoolean;
