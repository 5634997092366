//types
import { ILoaderProps } from "../components.types";

//assets
import { Loader as LoaderIcon } from "../../assets/icons";

//styles
import styles from "./Loader.module.scss";

const Loader = ({ color = "#54585F", width = 46 }: ILoaderProps) => {
  return (
    <div style={{ width, height: width }} className={styles.loader}>
      <LoaderIcon width={width} color={color} />
    </div>
  );
};

export default Loader;
