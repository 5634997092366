const PlayVideoIcon = ({ ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...rest}
    >
      <path
        d="M5 14.7831V9.21709C5 6.02599 5 4.43044 6.03046 3.8593C7.06093 3.28817 8.41395 4.13381 11.12 5.82509L15.5728 8.6081C18.0085 10.1304 19.2264 10.8916 19.2264 12.0001C19.2264 13.1086 18.0085 13.8698 15.5728 15.3921L11.12 18.1751C8.41395 19.8664 7.06093 20.712 6.03046 20.1409C5 19.5698 5 17.9742 5 14.7831Z"
        fill="white"
      />
    </svg>
  );
};

export default PlayVideoIcon;
